import { apolloClient } from "../../../apollo/apolloVueClient";
import { LIST_TAGS } from "../../../graphql/queries/allQueries";
import {
	CREATE_DEFINITIONS,
	DELETE_DEFINITIONS,
	UPDATE_DEFINITIONS,
} from "../../../graphql/mutations/tags_mutations";

export default {
	// initial state
	namespaced: true,
	state: {
		definitions: [],
	},

	//getters
	getters: {
		allDefinitions: (state) => {
			return state.definitions;
		},
		hasDefinitions: (state) => {
			return state.employees && state.employees.length > 0;
		},
	},

	// mutations
	mutations: {
		ADD_DEFINITION(state, payload) {
			let definitions = state.definitions.concat(payload);
			state.definitions = definitions;
		},
		SET_DEFINITION(state, payload) {
			state.definitions = payload;
		},
		EDIT_DEFINITION(state, payload) {
			state.definitions = state.definitions.map((definition) => {
				if (definition.tagId === payload.tagId) {
					return payload;
				}
				return definition;
			});
		},
		DELETE_DEFINITION(state, empID) {
			state.definitions = state.definitions.filter((emp) => {
				return emp.tagId !== empID;
			});
		},
	},

	// actions
	actions: {
		async createDefinitions({ commit }, payload) {
			const tagInput = {
				tag: payload.tag,
				tagType: payload.tagType,
			};
			//CREATE TAGS ON SERVER
			try {
				let response = await apolloClient.mutate({
					mutation: CREATE_DEFINITIONS,
					variables: {
						tagInput,
					},
				});
				let saveDefinitions = response.data?.createTag?.data;
				commit("ADD_DEFINITION", saveDefinitions);
				return saveDefinitions;
			} catch (error) {
				console.log(error);
			}
		},

		async fetchDefinitions({ commit }) {
			//GET ALL EMPLOYEES FROM SERVER
			try {
				let response = await apolloClient.query({
					query: LIST_TAGS,
					fetchPolicy: "network-only",
					variables: {
						size: 100,
						page: 0,
					},
				});
				commit("SET_DEFINITION", response.data?.listTags?.data);
			} catch (error) {
				console.log(error);
			}
		},

		async editDefinitions({ commit }, payload) {
			const tagInput = {
				tagId: payload.tagId,
				tag: payload.tag,
				tagType: payload.tagType,
			};
			//UPDATE TAGS ON SERVER
			try {
				let response = await apolloClient.mutate({
					mutation: UPDATE_DEFINITIONS,
					variables: {
						tagInput,
					},
				});
				let editEmployees = response.data?.updateTag?.data;
				commit("EDIT_DEFINITION", editEmployees);
				return editEmployees;
			} catch (error) {
				console.log(error);
			}
		},
		async deleteDefinitions({ commit }, payload) {
			return new Promise((resolve, reject) => {
				// api.delete(`${endpoint.deleteDefinitionsEndpoints}/${payload}`)
				apolloClient
					.mutate({
						mutation: DELETE_DEFINITIONS,
						variables: {
							id: payload,
						},
					})
					.then(() => {
						commit("DELETE_DEFINITION", payload);
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};
